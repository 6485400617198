import { createGlobalStyle } from 'styled-components';
import { RevelThemeType } from '@revel-transit/style-system';

const GlobalStyles = createGlobalStyle<{ theme: RevelThemeType }>`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  /*
  Remove default margin & padding
  */
  * {
    margin: 0;
    padding: 0;
  }

  /*
  Full width and height for root elements
  */
  html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
    font-family: Inter;
    background-color: ${({ theme }) => theme.colors.gray100};
  }
  
  #__next {
    height: 100%;
  }

  /* Text resets */
  /* avoid browser default inconsistent heading font-sizes */
  /* and pre/code too */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code {
    font-size: 1rem;
  }

  /* avoid browser default inconsistent heading font-sizes */
  /* and pre/code too */
  th,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  /*
  Improve media defaults
  */
  img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
  }

  /*
    Remove built-in form typography styles
  */
  input, button, textarea, select {
    font: inherit;
  }

  button {
    border: none;
    color: inherit;
    font: inherit;
  }

  /*
    Avoid text overflows
  */
  p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
  }

  /*
    Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed 
  */
  ul[role='list'],
  ol[role='list'] {
    list-style: none;
  }
`;

export default GlobalStyles;
