import { useMemo } from 'react';
import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  createHttpLink,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { signOut, getSession } from 'next-auth/client';

import getConfig from 'next/config';

// Service
import Logger from '../src/services/logger';

const { publicRuntimeConfig } = getConfig();

export function getApiUrl() {
  return publicRuntimeConfig.apolloUrl ?? process.env.REVEL_APOLLO_URL;
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getApolloClient = (ctx?: any, initialState?: NormalizedCacheObject) => {
  const httpLink = createHttpLink({
    uri: getApiUrl(),
  });

  // Log any GraphQL errors or network error that occurred
  const errorLink = onError((error) => {
    const { graphQLErrors } = error;

    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        const { extensions } = graphQLError;

        const logPayload = {
          sourceFunction: error?.operation?.operationName,
          message: extensions?.code,
          data: {
            app: 'apps/driver-ops',
            ...graphQLError,
            ...error,
          },
          ...(ctx && {
            ctx,
          }),
        };

        if (extensions && extensions.code === 'UNAUTHENTICATED') {
          Logger.info(logPayload);

          signOut();
        } else if (extensions?.code) {
          Logger.error(logPayload);
        }
      });
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // return the headers to the context so httpLink can read them
    const session = await getSession(ctx);
    return {
      headers: {
        ...headers,
        ...(session && {
          authorization: `identity ${session?.accessToken}`,
        }),
      },
    };
  });

  const driverOpsApolloLink = ApolloLink.from([authLink, errorLink, httpLink]);

  return new ApolloClient({
    ssrMode: Boolean(ctx), // set to true for SSR
    link: driverOpsApolloLink,
    cache: new InMemoryCache().restore(initialState || {}),
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
    },
  });
};

export function initializeApollo(initialState: any = null) {
  const _apolloClient = apolloClient ?? getApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export const useApollo = (initialState: any) => {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
};
