import { createContext, FC, useContext } from 'react';
import { useSession } from 'next-auth/client';
import { triggerApolloErrorNotification } from 'services/utils';
import { useGetNycSystemQueryQuery } from '../../generated/page';

type SystemState = {
  system?: {
    id?: string;
    settings?: {
      lowBatteryTagPercentage?: number;
    };
  };
};

const SystemContext = createContext<SystemState>({});

export const useSystemContext = () => {
  return useContext(SystemContext);
};

export const WithNycSystem: FC = ({ children }) => {
  const [session] = useSession();

  // Only fetch the system if the user is authenticated
  const { data } = useGetNycSystemQueryQuery({
    skip: !session,
    onError: (err) => {
      triggerApolloErrorNotification(err, 'Error loading system');
    },
  });

  return (
    <SystemContext.Provider value={{ system: data?.nycSystem }}>{children}</SystemContext.Provider>
  );
};
