import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from 'styled-components';
import { Box, ComponentsProvider, modals } from '@revel-transit/revel-components-internal';
import { Spotlight } from 'components/Spotlight';
import { AppHeader } from 'components/AppHeader';
import { RevelTheme } from '@revel-transit/style-system';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'next-auth/client';
import { Session } from 'next-auth';
import { WithNycSystem } from 'utils/system';

import { AppProps } from 'next/app';
import { datadogRum } from '@datadog/browser-rum';
import GlobalStyles from '../GlobalStyles';

// Font styles
import '../fontStyles.css';

import { getApiUrl, useApollo } from '../../lib/apolloClient';

datadogRum.init({
  applicationId: process.env.NEXT_PUBLIC_DD_RUM_APP_ID,
  clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
  env: process.env.NEXT_PUBLIC_VERCEL_ENV,
  site: 'datadoghq.com',
  service: 'driver-ops',
  // we will only deploy if there are file changes in this app
  // so we don't need to force turbo cache invalidation for this ENV var
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  sampleRate: 100,
  trackUserInteractions: true,
  allowedTracingOrigins: [getApiUrl()],
  sessionReplaySampleRate: process.env.NEXT_PUBLIC_DD_RUM_SESSION_REPLAY ? 100 : 0,
});

if (process.env.NEXT_PUBLIC_DD_RUM_SESSION_REPLAY) {
  datadogRum.startSessionReplayRecording();
}

const RevelApp = (props: AppProps) => {
  const { Component, pageProps } = props;

  const apolloClient = useApollo(pageProps.initialApolloState);

  const { router } = props;

  useEffect(() => {
    const handleRouteChange = () => {
      modals.closeAll();
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return (
    <Provider session={pageProps.session as Session}>
      {apolloClient && (
        <ApolloProvider client={apolloClient}>
          <WithNycSystem>
            <ThemeProvider theme={RevelTheme}>
              <GlobalStyles />
              <ComponentsProvider
                themeOverrides={{
                  other: {
                    headerHeight: 56,
                  },
                }}
              >
                <Spotlight />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <AppHeader />
                  <Component {...pageProps} />
                </Box>
              </ComponentsProvider>
            </ThemeProvider>
          </WithNycSystem>
        </ApolloProvider>
      )}
    </Provider>
  );
};

export default appWithTranslation(RevelApp);
