function _arrayLikeToArray(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterableToArrayLimit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest();
}
function _unsupportedIterableToArray(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _arrayLikeToArray(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}
var e = {
    breakpoints: [
        "480px",
        "839px",
        "1024px"
    ],
    fonts: {
        normal: "Inter,sans-serif",
        mono: "Roboto Mono",
        header: "Work-sans,sans-serif"
    },
    fontSizes: [],
    lineHeights: [],
    fontWeights: [
        300,
        400,
        500
    ],
    letterSpacings: [],
    radii: [],
    space: [],
    sizes: [],
    colors: {
        blue: {
            blueBlack: "#002F5C",
            veryDark: "#00588A",
            dark: "#0089BF",
            base: "#00B9F2",
            light: "#67EBFF",
            bg: "#E0F5FD",
            alt: "#009FD0"
        },
        green: {
            dark: "#007600",
            base: "#00A619",
            light: "#57D94E",
            bg: "#CCEDD1",
            blueGreen: "#E0FFF3"
        },
        orange: {
            dark: "#C43E00",
            base: "#FF6F00",
            light: "#FFA040",
            bg: "#FFEACC"
        },
        red: {
            dark: "#B80023",
            base: "#F2184A",
            light: "#FF5F75",
            bg: "#FDE3E9"
        },
        grey: {
            shade1: "#333333",
            shade2: "#4F4F4F",
            shade3: "#676767",
            shade4: "#828282",
            shade5: "#BDBDBD",
            shade6: "#E0E0E0",
            shade7: "#F2F2F2",
            shade8: "#FAFAFA"
        },
        purple: {
            light: "#F3E3FF"
        },
        white: "#ffffff",
        black: "#000000",
        transparent: "#00000000",
        brand: {
            black: "#231F20",
            blue: "#00B9F2",
            white: "#ffffff",
            functionalBlue: "#00B9F2",
            darkBlue: "#3A78B2",
            lightestBlue: "#E5F1F5",
            grayBlue: "#F2F8FA",
            incentiveLight: "#EBEFFF",
            incentiveDark: "#4858DF"
        },
        green200: "#e0fff2",
        green300: "#c3ffe7",
        green400: "#a6ffdb",
        green500: "#48e5a7",
        green600: "#00b56f",
        green700: "#007347",
        orange200: "#fff4e6",
        orange300: "#ffe6c6",
        orange400: "#ffd8a6",
        orange500: "#faaf57",
        orange600: "#e0780f",
        orange700: "#733600",
        blue200: "#e0eeff",
        blue300: "#c3ddff",
        blue400: "#a6ccff",
        blue500: "#488be5",
        blue600: "#004eb5",
        blue700: "#003273",
        revelBlue200: "#D5F0F9",
        revelBlue300: "#6BD4F6",
        revelBlue400: "#00B9F2",
        revelBlue500: "#019AC9",
        revelBlue600: "#017AA0",
        revelBlue700: "#005F7C",
        revelBlueRefresh50: "#E5F1F5",
        revelBlueRefresh100: "#D2F2FC",
        revelBlueRefresh200: "#ADEEFF",
        revelBlueRefresh300: "#7BE3FF",
        revelBlueRefresh400: "#3DD6FF",
        revelBlueRefresh500: "#00B9F2",
        revelBlueRefresh600: "#1E9CD4",
        revelBlueRefresh700: "#3C7EB5",
        revelBlueRefresh800: "#225B8A",
        revelBlueRefresh900: "#123D60",
        purple200: "#f2e0ff",
        purple300: "#e5c3ff",
        purple400: "#d8a6ff",
        purple500: "#a148e5",
        purple600: "#6900b5",
        purple700: "#430073",
        red200: "#ffe2e0",
        red300: "#ffc7c3",
        red400: "#ffaca6",
        red500: "#e55348",
        red600: "#b50c00",
        red700: "#730800",
        yellow200: "#fdffe5",
        yellow300: "#fbffc6",
        yellow400: "#f9ffa6",
        yellow500: "#f5fa56",
        yellow600: "#e0dc0d",
        yellow700: "#736900",
        gray100: "#fbfcfd",
        gray200: "#f7fafb",
        gray300: "#ecf2f4",
        gray400: "#dde6e8",
        gray500: "#cbd5d8",
        gray600: "#b5bec1",
        gray700: "#98a1a4",
        gray800: "#757c7f",
        gray900: "#4d5253"
    }
}, r = function(r1) {
    var f1 = e.colors, ref = _slicedToArray(r1.split("."), 2), l = ref[0], a = ref[1];
    return a ? f1[l][a] : f1[l];
}, f = function(r2) {
    var f2 = e.colors, ref = _slicedToArray(r2.split("."), 2), l = ref[0], a = ref[1];
    return a ? f2[l][a] : f2[l];
};
export { e as RevelTheme, f as getRevelColor, r as getRevelLegacyColor };
